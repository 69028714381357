define("ember-this-fallback/deprecations-helper", ["exports", "@ember/component/helper", "@ember/debug", "ember-this-fallback/types/deprecations"], function (_exports, _helper, _debug, _deprecations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Calls @ember/debug `deprecate` for each provided set of `deprecate` params.
   */
  const deprecationsHelper = (0, _helper.helper)(_ref => {
    let [deprecationsJson] = _ref;
    const deprecations = (0, _deprecations.assertIsDeprecations)(JSON.parse(deprecationsJson));
    for (const deprecation of deprecations) {
      (true && !(false) && (0, _debug.deprecate)(...deprecation));
    }
  });
  var _default = deprecationsHelper;
  _exports.default = _default;
});