define("ember-this-fallback/try-lookup-helper", ["exports", "@ember/component/helper", "ember-this-fallback/get-owner", "ember-this-fallback/types/util"], function (_exports, _helper, _getOwner, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Returns the helper `Factory` for the helper with the provided name if it
   * exists and `undefined` if not.
   * Similar to `helper` helper, but avoids build-time errors for
   * this-fallback invocations.
   */
  class TryLookupHelper extends _helper.default {
    compute(_ref) {
      let [name] = _ref;
      const owner = (0, _util.assertExists)((0, _getOwner.getOwner)(this), 'Could not find owner');
      return owner.factoryFor(`helper:${name}`)?.class;
    }
  }
  _exports.default = TryLookupHelper;
});