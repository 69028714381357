define("ember-this-fallback/is-component", ["exports", "@ember/component/helper", "ember-this-fallback/get-owner", "ember-this-fallback/types/util"], function (_exports, _helper, _getOwner, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** Checks if a component with the provided name exists. */
  class IsComponent extends _helper.default {
    compute(_ref) {
      let [name] = _ref;
      const owner = (0, _util.assertExists)((0, _getOwner.getOwner)(this), 'Could not find owner');
      return Boolean(owner.factoryFor(`component:${name}`));
    }
  }
  _exports.default = IsComponent;
});