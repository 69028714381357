define("ember-this-fallback/types/util", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertExists = assertExists;
  _exports.isRecord = isRecord;
  _exports.isString = isString;
  /** Asserts that the given value is not undefined. */
  function assertExists(value) {
    let message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'assertExists failed';
    (false && !(value !== undefined) && (0, _debug.assert)(message, value !== undefined));
    return value;
  }
  function isRecord(value) {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }
  function isString(value) {
    return typeof value === 'string';
  }
});