define("ember-this-fallback/this-fallback-helper", ["exports", "@ember/component/helper", "@ember/debug", "@ember/object", "ember-this-fallback/types/deprecations"], function (_exports, _helper, _debug, _object, _deprecations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Looks up the given `path` on the given `context` and returns it.
   *
   * If `deprecationJson` is JSON stringified `deprecate` params, `deprecate`
   * will be called with those params.
   */
  const thisFallbackHelper = (0, _helper.helper)(_ref => {
    let [context, path, deprecationJson] = _ref;
    if (deprecationJson) {
      const deprecation = (0, _deprecations.assertIsDeprecation)(JSON.parse(deprecationJson));
      (true && !(false) && (0, _debug.deprecate)(...deprecation));
    }
    return (0, _object.get)(context, path);
  });
  var _default = thisFallbackHelper;
  _exports.default = _default;
});