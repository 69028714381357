define("ember-this-fallback/types/deprecations", ["exports", "@ember/debug", "ember-this-fallback/types/util"], function (_exports, _debug, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertIsDeprecation = assertIsDeprecation;
  _exports.assertIsDeprecations = assertIsDeprecations;
  function isDeprecation(value) {
    if (Array.isArray(value) && value.length === 3) {
      const [message, _test, options] = value;
      return (0, _util.isString)(message) && (0, _util.isRecord)(options) && (0, _util.isString)(options.id);
    } else {
      return false;
    }
  }
  function assertIsDeprecation(value) {
    (false && !(isDeprecation(value)) && (0, _debug.assert)('value was expected to be a `deprecate` params array', isDeprecation(value)));
    return value;
  }
  function assertIsDeprecations(value) {
    (false && !(Array.isArray(value) && value.every(isDeprecation)) && (0, _debug.assert)('value was expected to be am array of `deprecate` params arrays', Array.isArray(value) && value.every(isDeprecation)));
    return value;
  }
});