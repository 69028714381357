define("ember-functions-as-helper-polyfill/initializers/usable-function-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    // This exists just to prevent ember-could-get-used-to-this'
    // function-manager from loading
    // see: https://github.com/tracked-tools/ember-could-get-used-to-this/blob/master/addon/initializers/usable-function-manager.js
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});