define("ember-this-fallback/get-owner", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getOwner = getOwner;
  /**
   * Re-export of the `getOwner` export from `@ember/application`.
   * This export is deprecated as of
   * [Ember 4.10](https://github.com/emberjs/ember.js/releases/tag/v4.10.0)
   * but we need to support Ember versions prior to 4.10 also.
   */
  function getOwner(object) {
    return (0, _application.getOwner)(object);
  }
});